import { Stack } from '@guider-global/ui';
import { Box } from '@mui/material';
import { LanguageMenu } from 'components/LanguageMenu';
import { NavbarButton } from 'components/Navbar/NavbarButton';
import { INavbarLink } from '..';
import { IOrganizationLanguage } from '@guider-global/sanity-api';

export interface NavbarUnauthenticatedActionsContainerProps {
  show?: boolean;
  actions: INavbarLink[];
  localeCode?: string;
  languageOptions: IOrganizationLanguage[];
  ariaLabelLanguage: string | undefined;
  onClickChangeLanguage: (localeCode: string) => void;
  isMobile: boolean;
}

export const NavbarUnauthenticatedActionsContainer = ({
  show,
  actions,
  languageOptions,
  localeCode,
  ariaLabelLanguage,
  onClickChangeLanguage,
  isMobile,
}: NavbarUnauthenticatedActionsContainerProps) => {
  if (!show) return <></>;
  return (
    <Stack direction={'row'}>
      <LanguageMenu
        localeCode={localeCode}
        languageOptions={languageOptions}
        ariaLabel={ariaLabelLanguage}
        onClickChange={onClickChangeLanguage}
      />
      {!isMobile && (
        <Box
          component="nav"
          sx={{ display: { xs: 'none', md: 'flex' }, height: '36px' }}
        >
          {actions.map((link, index) => {
            return (
              <NavbarButton
                link={link}
                key={link.key ?? `link-${index}`}
                data-cy={`components_Navbar_unauthenticated_${link.label}-button`}
                isUnauthenticated
                height="40px"
              />
            );
          })}
        </Box>
      )}
    </Stack>
  );
};
