import { Box, PopoverVirtualElement } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Menu, MenuItemProps, NavbarLogo } from 'components';
import { INavbarLink } from '..';
import { NavbarButton } from 'components/Navbar/NavbarButton';
import { StickyNote2 } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { SanityOrganizationWhiteLabelLogoSize } from '@guider-global/shared-types';

export interface NavbarDeskopLinksContainerProps {
  links: INavbarLink[];
  additionalNavLinks?: MenuItemProps[];
  additionalNavLinksLabel: string | undefined;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  onDesktopLinksClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDesktopLinksClickClosed: (
    event?: unknown,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  isAuthenticated?: boolean;
  organizationLogo?: string;
  logoSize?: SanityOrganizationWhiteLabelLogoSize;
}

export function NavbarDeskopLinksContainer({
  links,
  additionalNavLinks,
  additionalNavLinksLabel,
  anchorEl,
  onDesktopLinksClickOpen,
  onDesktopLinksClickClosed,
  isAuthenticated,
  logoSize,
  organizationLogo,
}: NavbarDeskopLinksContainerProps) {
  const location = useLocation();
  return (
    <>
      <NavbarLogo src={organizationLogo} size={logoSize} />
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          alignItems: 'stretch',
          height: '100%',
        }}
      >
        {isAuthenticated &&
          links.map((link, index) => (
            <NavbarButton
              link={link}
              key={link.key ?? `desktop-link-${index}`}
              data-cy={link.dataCy}
              isActive={
                link.route ? location.pathname.startsWith(link.route) : false
              }
            />
          ))}
        <Menu
          items={additionalNavLinks ?? []}
          anchorEl={anchorEl}
          onMenuClickOpen={onDesktopLinksClickOpen}
          onMenuClickClosed={onDesktopLinksClickClosed}
          hidden={additionalNavLinks?.length === 0}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <StickyNote2 />
            {additionalNavLinksLabel}
            {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </Menu>
      </Box>
    </>
  );
}
