import { IOrganizationLanguage } from '@guider-global/sanity-api';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IProfile, IUser } from '@guider-global/shared-types';
import { Avatar, AvatarBox } from '@guider-global/ui';
import {
  AccountBox,
  EventAvailable,
  Logout,
  PersonAdd,
  Reviews,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  PopoverVirtualElement,
} from '@mui/material';
import { MenuItemProps, MenuItems } from 'components';
import { LanguageNested } from 'components/LanguageNested';

import { useMixpanelEvents, useProfilePicture } from 'hooks';
import { Link as RouterLink } from 'react-router-dom';

import { NavbarMenuProfileCardView } from 'views';

export interface NavbarUserMenuContainerProps {
  profile?: IProfile;
  user?: IUser;
  show?: boolean;
  languageOptions: IOrganizationLanguage[];
  onChangeLocaleCode: (localeCode: string) => void;
  localeCode?: string;
  isIntegrationEnabled: boolean;
  onMenuClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMenuClickClosed: (
    event?: unknown,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  children?: React.ReactNode;
  isMobile: boolean;
  onClickLogout: () => void;
}
export const NavbarUserMenuContainer = ({
  user,
  profile,
  show,
  languageOptions,
  isIntegrationEnabled,
  onChangeLocaleCode,
  localeCode,
  onMenuClickClosed,
  onMenuClickOpen,
  anchorEl,
  isMobile,
  children,
  onClickLogout,
}: NavbarUserMenuContainerProps) => {
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const navigation = baseLanguage?.navigation;

  // - MixPanel
  const { trackMixpanelEvent } = useMixpanelEvents({});

  const integrationsNavUserMenuItem = isIntegrationEnabled
    ? [
        {
          action: () => {
            onMenuClickClosed();
            trackMixpanelEvent('Navigation - User Settings - Integrations');
          },
          component: RouterLink,
          to: '/settings/integrations',
          'data-cy': 'components_Navbar_settings_integrations-link',
          label: navigation?.user_action_menu?.integrations_label,
          icon: <PersonAdd />,
        },
      ]
    : [];
  const navUserMenuItems: MenuItemProps[] = [
    {
      action: () => {
        onMenuClickClosed();
        trackMixpanelEvent('Navigation - User Settings - Profile');
      },
      component: RouterLink,
      to: '/settings/profile',
      'data-cy': 'components_Navbar_settings_profile-link',
      label: navigation?.user_action_menu?.profile_link_label,
      icon: <AccountBox />,
    },
    {
      action: () => {
        onMenuClickClosed();
        trackMixpanelEvent('Navigation - User Settings - Account');
      },
      component: RouterLink,
      to: '/settings/account',
      'data-cy': 'components_Navbar_settings_account-link',
      label: navigation?.user_action_menu?.account_link_label,
      icon: <Settings />,
    },
    ...integrationsNavUserMenuItem,
    {
      action: () => {
        onMenuClickClosed();
        trackMixpanelEvent('Navigation - User Settings - Availability');
      },
      component: RouterLink,
      to: '/settings/availability',
      'data-cy': 'components_Navbar_settings_availability-link',
      label: navigation?.user_action_menu?.availability_link_label,
      icon: <EventAvailable />,
    },
    {
      action: () => {
        onMenuClickClosed();
      },
      component: RouterLink,
      to: '/settings/reviews',
      'data-cy': 'components_Navbar_settings_reviews_link',
      label: navigation?.user_action_menu?.reviews_link_label,
      icon: <Reviews />,
    },
    {
      action: () => {
        onClickLogout();
      },
      component: RouterLink,
      'data-cy': 'components_Navbar_logout-link',
      label: navigation?.user_action_menu?.log_out_link_label,
      icon: <Logout />,
    },
  ];

  const { picture } = useProfilePicture();

  if (!show) return <></>;
  return (
    <Box
      sx={{
        minWidth: !isMobile ? '200px' : '100%',
        padding: '8px 12px',
        gap: !isMobile ? 1.5 : 0,
        display: 'flex',
        flexDirection: !isMobile ? 'row' : 'column',
        alignItems: !isMobile ? 'center' : 'flex-start',
        overflowX: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      {children}
      <IconButton
        size="large"
        disableRipple
        edge="start"
        color="inherit"
        onClick={onMenuClickOpen}
        type="button"
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : 'false'}
        aria-controls="user-menu"
        sx={{
          mr: { xs: -1, md: 0 },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        data-cy="components_Navbar_avatar-button"
      >
        {!isMobile ? (
          <Avatar
            sx={{
              borderRadius: '50%',
              maxWidth: '44px',
              maxHeight: '44px',
            }}
            src={picture}
            size="small"
          />
        ) : (
          <AvatarBox
            avatarSrc={picture}
            size={'xs'}
            heading={profile?.displayName}
            subtitles={[user?.email]}
            avatarSx={{ borderRadius: '50%' }}
            sx={{
              pointerEvents: 'none',
              width: '100%',
            }}
          />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        aria-hidden={anchorEl ? 'true' : 'false'}
        open={Boolean(anchorEl)}
        onClose={onMenuClickClosed}
        id="user-menu"
        sx={{
          '& .MuiPaper-root': {
            width: isMobile ? '77%' : 'auto',
            minWidth: isMobile ? '77%' : '200px',
          },
        }}
      >
        {!isMobile && (
          <NavbarMenuProfileCardView
            onClick={onMenuClickClosed}
            label={navigation?.user_action_menu?.profile_link_label}
            to={'/settings/profile'}
            user={user}
            profile={profile}
          />
        )}
        <Divider />
        <MenuItems items={navUserMenuItems} />
        <Divider />
        <LanguageNested
          localeCode={localeCode}
          languageOptions={languageOptions}
          ariaLabel={navigation?.user_action_menu?.translation_dropdown_label}
          onClickChange={onChangeLocaleCode}
        />
      </Menu>
    </Box>
  );
};
