import { SanityAdditionalNavigationLink } from '@guider-global/shared-types';
import { Divider } from '@guider-global/ui';
import Menu from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, List, SxProps, Theme } from '@mui/material';
import { NavLinkList } from 'components/Navbar/NavbarMobileDrawer/NavLinkList';
import { NestedListAdditionalNavLinks } from 'components/Navbar/NavbarMobileDrawer/NestedListAdditionalNavLinks';
import { INavbarLink } from 'containers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface NavbarMobileDrawerContainerProps {
  links: INavbarLink[];
  additionalNavLinks: SanityAdditionalNavigationLink[];
  additionalNavLinksLabel: string | undefined;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const NavbarMobileDrawerContainer = ({
  links,
  additionalNavLinks,
  additionalNavLinksLabel,
  children,
  sx,
}: NavbarMobileDrawerContainerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // Route
  const navigate = useNavigate();
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  function handleLinkClick(link: INavbarLink) {
    if (link.type === 'link' && link.route) {
      setIsDrawerOpen(false);
      navigate(link.route);
    } else if (link.type === 'button' && link.action) {
      setIsDrawerOpen(false);
      link.action();
    }
  }

  return (
    <Box sx={sx}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        sx={{ zIndex: 1, display: 'block' }}
      >
        <Menu sx={{ fontSize: '35px' }} />
      </IconButton>
      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List component="nav" sx={{ width: '85vw' }}>
          <>
            <NavLinkList links={links} linkActionCallback={handleLinkClick} />
            <NestedListAdditionalNavLinks
              label={additionalNavLinksLabel}
              links={additionalNavLinks}
            />
            <Divider />
            <Box sx={{ mt: 'max' }}>{children}</Box>
          </>
        </List>
      </Drawer>
    </Box>
  );
};
