import { useAuth } from '@guider-global/auth-hooks';
import {
  getOrgAdminUrl,
  getOrigin,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
  useBaseLanguage,
} from '@guider-global/sanity-hooks';
import { SanityOrganizationWhiteLabelLogoSize } from '@guider-global/shared-types';
import { Stack } from '@guider-global/ui';
import {
  AddToQueue,
  Dashboard,
  Headset,
  PeopleOutline,
  School,
  Shield,
} from '@mui/icons-material';
import { AppBar, Link, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { MenuItemProps } from 'components';
import {
  NavbarButton,
  NavbarLogo,
  NavbarSupportButton,
} from 'components/Navbar';
import { useLocalization, useMixpanelEvents } from 'hooks';
import { HTMLAttributeAnchorTarget, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { selectRegistrationType } from 'store/slices/appSlice';
import {
  NavbarDeskopLinksContainer,
  NavbarMobileDrawerContainer,
  NavbarUnauthenticatedActionsContainer,
  NavbarUserMenuContainer,
} from '..';
import { app as teamsApp, authentication } from '@microsoft/teams-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProfiles, useUsers } from '@guider-global/front-end-hooks';

export interface INavbarLink {
  type: 'button' | 'link';
  label: string | undefined;
  route?: string;
  action?: () => Promise<void> | void;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  textColor?: string;
  key?: number | string;
  dataCy: string;
  icon?: React.ReactElement;
  target?: HTMLAttributeAnchorTarget;
}

export interface NavbarContainerProps {
  hidden?: boolean;
}

export function NavbarContainer({ hidden }: NavbarContainerProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isConfigurationPage = pathname.includes('configure');

  // Redux
  const { unauthenticatedActions: navbarUnauthenticatedActions } =
    useAppSelector((state: RootState) => {
      return state.app.navbar;
    });
  const registrationPageType = useAppSelector(selectRegistrationType);
  const isRegistrationTypeNone = registrationPageType === 'none';

  const isTeamsInitialized = teamsApp.isInitialized();

  // State
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(
    null,
  );
  const [desktopLinksAnchor, setDesktopLinksAnchor] =
    useState<null | HTMLElement>(null);

  // Auth0
  const { logout, isAuthenticated, scopesGranted, loginWithRedirect } = useAuth(
    {
      waitForAuthentication: true,
    },
  );

  const isAdmin = scopesGranted.join('').includes('admin');

  // MixPanel
  const { trackMixpanelEvent, resetUserIdentity } = useMixpanelEvents({});

  // - Intercom
  const {
    show: showIntercom,
    hide: hideIntercom,
    isOpen: isIntercomOpen,
  } = useIntercom();

  // User
  const { user } = useUsers({});

  // Profile
  const { profile } = useProfiles({});

  // Base Language

  const organizationSlug = getSubDomain();

  const { localeCode, handleLanguageChange } =
    useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const navigation = baseLanguage?.navigation;
  // Organization
  const { organization } = useOrganization({
    organizationSlug,
    localeCode,
  });

  const organizationLanguages = organization?.organizationLanguages ?? [];
  const organizationAuth0Id =
    organization?.basic_info.auth0_organization_id || '';
  // Mobile
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));

  const organizationLogo = buildSanityImageUrl({
    source: organization?.white_label.logo ?? '',
  });
  const enabledIntegrations = organization?.white_label.integrations;

  const logoSize = organization?.white_label.logo_size;
  const videoConferencing = enabledIntegrations?.video_conferencing;
  const calendar = enabledIntegrations?.calendar;
  const activeDirectoryTeams =
    videoConferencing?.active_directory_teams ?? false;
  const activeDirectoryOutlookCalendar =
    calendar?.active_directory_outlook_calendar ?? false;
  const isIntegrationEnabled =
    activeDirectoryTeams || activeDirectoryOutlookCalendar;

  //   handlers
  const handleAdditionalNavLinksClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setDesktopLinksAnchor(event.currentTarget);
    trackMixpanelEvent('Navigation - Additional resources dropdown');
  };
  const handleAdditionalNavLinksClose = () => {
    setDesktopLinksAnchor(null);
  };
  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuAnchor(event.currentTarget);
    trackMixpanelEvent('Navigation - User Settings dropdown');
  };
  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };
  const handleUserChangeLanguage = (localeCode: string) => {
    handleLanguageChange(localeCode);
    handleUserMenuClose();
  };
  const handleLogout = async () => {
    try {
      await logout({ logoutParams: { returnTo: getOrigin() } });
      trackMixpanelEvent('Navigation - User Settings - Log out');
      resetUserIdentity();
    } catch (err) {
      console.error(err);
    }
  };

  const filteredAdditionalNavLinks = !isAuthenticated
    ? organization?.white_label?.additional_nav_links?.filter(
        (link) => !link.is_private,
      ) || []
    : organization?.white_label?.additional_nav_links ?? [];

  const formattedAdditionalNavLinks: MenuItemProps[] =
    filteredAdditionalNavLinks?.map((item) => {
      return {
        key: `other-resources-link-${item._key}`,
        action: handleAdditionalNavLinksClose,
        href: item.url,
        label: item.label,
        component: Link,
        'data-cy': item._key,
        muiItemProps: {
          target: item.target,
          rel: 'noopener noreferrer',
          underline: 'none',
          color: 'inherit',
        },
      };
    });

  const authenticatedLinks: INavbarLink[] = [
    {
      route: '/dashboard',
      label: `${navigation?.dashboard_link_label}`,
      type: 'link',
      dataCy: 'navbar-authenticated-dashboard-button',
      icon: <Dashboard />,
    },
    {
      route: '/programs',
      label: `${navigation?.programs_link_label}`,
      type: 'link',
      key: 1,
      dataCy: 'navbar-authenticated-programs-button',
      icon: <AddToQueue />,
    },

    {
      route: '/relationships',
      label: `${navigation?.relationships_link_label}`,
      type: 'link',
      key: 2,
      dataCy: 'navbar-authenticated-relationships-button',
      icon: <PeopleOutline />,
    },
    {
      route: '/learn',
      label:
        organization?.learning_hub.navigation_label ??
        `${navigation?.learning_center_link_label}`,
      type: 'link',
      key: 3,
      dataCy: 'navbar-authenticated-learning-center-button',
      icon: <School />,
    },
  ];

  const supportLink: INavbarLink = {
    label: `${navigation?.support_link_label}`,
    type: 'button',
    icon: <Headset />,
    action: () => {
      trackMixpanelEvent('Navigation - Support');
      isIntercomOpen ? hideIntercom() : showIntercom();
    },
    key: 4,
    dataCy: 'navbar-authenticated-support-button',
  };

  const adminLink: INavbarLink = {
    route: getOrgAdminUrl(organizationSlug).toString(),
    label: `${navigation?.guider_admin_label}`,
    type: 'link',
    key: 5,
    dataCy: 'navbar-authenticated-admin-button',
    icon: <Shield />,
    target: '_blank',
  };

  if (isAdmin) {
    authenticatedLinks.push(adminLink);
  }

  const getUnauthenticatedActions = (): INavbarLink[] => {
    if (isRegistrationTypeNone) return [];

    if (navbarUnauthenticatedActions) return navbarUnauthenticatedActions;

    return [
      {
        label: `${navigation?.login_button_label}`,
        route: '/login',
        type: 'button',
        variant: 'outlined',
        key: 1,
        dataCy: 'navbar-login-button',
      },
      {
        label: `${navigation?.register_button_label}`,
        route: '/register/account',
        type: 'button',
        variant: 'contained',
        color: 'info',
        key: 2,
        dataCy: 'navbar-unauthenticated-register-button',
      },
    ];
  };

  const unauthenticatedActions: INavbarLink[] = getUnauthenticatedActions();

  if (hidden) return <></>;

  // Logo sizes: default = '', large = '', extra-large = ''

  const LOGO_SIZE_MULTIPLIER = {
    [SanityOrganizationWhiteLabelLogoSize.Default]: 1,
    [SanityOrganizationWhiteLabelLogoSize.Large]: 2,
    [SanityOrganizationWhiteLabelLogoSize.ExtraLarge]: 3,
  };

  const multiplier = LOGO_SIZE_MULTIPLIER[logoSize ?? 'default'];

  const teamsAuthenticate = async () => {
    await loginWithRedirect({
      authorizationParams: {
        organization: organizationAuth0Id,
        redirect_uri: `${window.location.origin}/teams-auth`,
      },
      openUrl: async (auth0Url) => {
        const code = await authentication.authenticate({
          url: auth0Url,
        });

        navigate(`/login?code=${code}`);
      },
    });
  };

  if (isConfigurationPage) {
    return <></>;
  }

  return (
    <AppBar position="sticky" sx={{ boxShadow: 'none' }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          minHeight: `${multiplier * 67}px`,
          maxHeight: `${multiplier * 67}px`,
          display: 'flex',
        }}
      >
        {isMobile ? (
          <>
            {isAuthenticated ? (
              <Stack
                direction={'row'}
                justifyContent={'center'}
                width={'100%'}
                position={'relative'}
                alignItems={'center'}
              >
                <NavbarMobileDrawerContainer
                  sx={{ position: 'absolute', left: 0 }}
                  links={[...authenticatedLinks, supportLink]}
                  additionalNavLinks={filteredAdditionalNavLinks}
                  additionalNavLinksLabel={
                    navigation?.other_resources_link_label
                  }
                >
                  <NavbarUserMenuContainer
                    user={user}
                    profile={profile}
                    show={isAuthenticated}
                    languageOptions={organizationLanguages}
                    onChangeLocaleCode={handleUserChangeLanguage}
                    localeCode={localeCode}
                    isIntegrationEnabled={isIntegrationEnabled}
                    onMenuClickOpen={handleUserMenuClick}
                    onMenuClickClosed={handleUserMenuClose}
                    anchorEl={userMenuAnchor}
                    isMobile={isMobile}
                    onClickLogout={handleLogout}
                  />
                </NavbarMobileDrawerContainer>
                <NavbarLogo src={organizationLogo} size={logoSize} />
              </Stack>
            ) : (
              <>
                <NavbarMobileDrawerContainer
                  links={
                    isTeamsInitialized
                      ? [
                          {
                            type: 'button',
                            label: 'Login',
                            action: teamsAuthenticate,
                            dataCy: '',
                          },
                        ]
                      : unauthenticatedActions
                  }
                  additionalNavLinks={filteredAdditionalNavLinks}
                  additionalNavLinksLabel={
                    navigation?.other_resources_link_label
                  }
                />
                <NavbarLogo src={organizationLogo} size={logoSize} />
              </>
            )}
          </>
        ) : (
          <>
            <NavbarDeskopLinksContainer
              links={authenticatedLinks}
              additionalNavLinks={formattedAdditionalNavLinks}
              anchorEl={desktopLinksAnchor}
              onDesktopLinksClickOpen={handleAdditionalNavLinksClick}
              onDesktopLinksClickClosed={handleAdditionalNavLinksClose}
              additionalNavLinksLabel={navigation?.other_resources_link_label}
              isAuthenticated={isAuthenticated}
              organizationLogo={organizationLogo}
              logoSize={logoSize}
            />
            {isAuthenticated && (
              <NavbarUserMenuContainer
                user={user}
                profile={profile}
                show={isAuthenticated}
                languageOptions={organizationLanguages}
                onChangeLocaleCode={handleUserChangeLanguage}
                localeCode={localeCode}
                isIntegrationEnabled={isIntegrationEnabled}
                onMenuClickOpen={handleUserMenuClick}
                onMenuClickClosed={handleUserMenuClose}
                anchorEl={userMenuAnchor}
                isMobile={isMobile}
                onClickLogout={handleLogout}
              >
                <NavbarSupportButton supportLink={supportLink} />
              </NavbarUserMenuContainer>
            )}
          </>
        )}
        {!isTeamsInitialized && (
          <NavbarUnauthenticatedActionsContainer
            isMobile={isMobile}
            actions={unauthenticatedActions}
            show={!isAuthenticated}
            languageOptions={organizationLanguages}
            ariaLabelLanguage={
              navigation?.user_action_menu?.translation_dropdown_label
            }
            onClickChangeLanguage={handleLanguageChange}
            localeCode={localeCode}
          />
        )}
        {!isConfigurationPage &&
          isTeamsInitialized &&
          !isAuthenticated &&
          !isRegistrationTypeNone && (
            <NavbarButton
              link={{
                type: 'button',
                label: 'Login',
                action: teamsAuthenticate,
                dataCy: '',
              }}
              key="teams-login"
              data-cy={`components_Navbar_unauthenticated_teams-button`}
              isUnauthenticated
              height="40px"
            />
          )}
      </Toolbar>
    </AppBar>
  );
}
